
import "jquery";

const Togglers = function () {

	let togglerClass = ".toggles-target",
		triggerConfirm = ".trigger-confirm";

	function init() {

		$(document).on("click",
			triggerConfirm,
			function(e) {
				e.preventDefault();
				$(this).next(".confirm").fadeIn();
			});

		$(document).on("click",
			togglerClass,
			function(e) {
				e.preventDefault();
				// Toggle class for both toggler and target
				const target = "#" + $(this).attr("data-target-id");
				$(this).add(target).toggleClass("toggled");
			});
	
		const navToggler = $(".nav-toggler");
		navToggler.each(function() {
			let target = $(this).parent().parent().next("ul");
			toggleAction($(this), target);
		});

		const togglesNext = $(".toggles-next");
		togglesNext.each(function() {
			toggleAction($(this), $(this).next());
		});

		const closeParent = $("[data-action=close-parent]");
		closeParent.click(function() {
			$(this).parent().fadeOut();
		});
	};

function toggleAction(el, target) {
	el.bind("click",
		((e) => {
			e.preventDefault();
			el.toggleClass("toggled");
			target.slideToggle().toggleClass("toggled");
		}));
	// Buttons trigger click on enter
	if (!el.is("button")) {
		el.bind("keyup",
			function(e) {
				e.preventDefault();
				// Trigger click on enter keypress
				if (e.keyCode === 13) {
					el.trigger("click");
				}
			});
	}
}

return {
	init: init
};
};

/*
 * HelseKlage general, public script
 */
window.HKGeneral = function () {
	/*
	 * Config for all front end editors.
	 */
	var HKConfig = window.HKConfig; // Helseklage config values
	var tinyMCEConfig = {
		selector: '.tinymce',
		plugins: 'link image paste emoticons lists',
		encoding: 'xml',
		paste_as_text: true,
		menubar: false,

		block_formats: 'Paragraph=p;Header 2=h2;Header 3=h3;Header 4=h4',
		toolbar1:
		"formatselect | undo redo | bold italic  | numlist bullist | alignleft aligncenter alignright | emoticons link image",

		files_upload_url: "/umbraco/Surface/WallPost/AddEditorFile",
		file_picker_types: "file image media",
		relative_urls: false,
		remove_script_host: false,
		convert_urls: true,
		images_upload_credentials: true,
		image_dimensions: false,

		// Tinymce file picker handler (for both images and document links)
		file_picker_callback: function (callBack) {
			var input = document.createElement('input');
			input.setAttribute('type', 'file');
			input.setAttribute('accept',
				HKConfig.TinyMceAllowedUploadFileTypes);
			input.onchange = function () {

				// Add selected file to formdata object
				var file = this.files[0];
				var formData = new FormData();
				formData.append("file", file);

				// Create new http request
				var request = new XMLHttpRequest();
				request.open("POST", "/umbraco/Surface/WallPost/AddEditorFile", true);
				request.send(formData);

				// When request state changes
				request.onreadystatechange = function () {
					// When POST request is done and response received
					if (this.readyState === XMLHttpRequest.DONE) {
						var response = JSON.parse(request.response);
						// call the callback and populate the Title field with the file name
						callBack(response.location, {
							text: response.name,
							title: response.name,
							alt: response.name
						});
					}
				}

			};
			// Click input to trigger local file browser
			input.click();
		}
}
	function notImplementedMessage() {
		return "<div class='info-box fade-out margin-bottom'>" +
			"Denne funksjonen er dessverre ikke klar til bruk enda." +
			"</div>";
	}
	function init() {
		// Force window reload on back-forward cache
		$(window).bind("pageshow",
			function(event) {
				if (event.originalEvent.persisted) {
					window.location.reload();
				}
			});

		// Make all external links open in new tab
		$("a").each(function() {
			var a = new RegExp("/" + window.location.host + "/");
			if (!a.test(this.href)) {
				$(this).click(function(event) {
					event.preventDefault();
					event.stopPropagation();
					window.open(this.href, "_blank");
				});
			}
		});
		// Active search field when toggled 
		$("[data-target-id=main-search]").click(function() { 
			setTimeout(function() {
					$("#main-search").find("input").first().focus();
				},0);
		});
		// When trying to use an action not yet implemented, tell user about this.
		$(document).on("click", ".not-implemented",
			function(e) {
				e.preventDefault();
				$(this).after(notImplementedMessage());
			});
		// Click animates scroll to target
		$(document).on("click",
			"[data-scroll-to]",
			function () {
				var target = $("#" + $(this).attr("data-scroll-to"));
				target.show();

				$("html, body").animate({
					scrollTop: target.offset().top
			});
			});
	}

	return {
		init: init,
		tinyMCESettings: tinyMCEConfig,
		notImplementedMessage: notImplementedMessage
};
};

$(() => {
	Togglers().init();
	HKGeneral().init();
});